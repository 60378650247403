$primary-color: #3498db;
$secondary-color: #2ecc71;
$background-color: #F5F5F3;
$background-color--dark: #2A2A2A;

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --background-color: #{$background-color};
  --background-color--dark: #{$background-color--dark};
}

.light {
  --background-color: #{$background-color};
  @apply text-neutral-900;
}

.dark {
  --background-color: #{$background-color--dark};
  @apply text-neutral-200;
}


.home-page {
  @apply flex flex-col flex-1 h-full items-center;

  &__hero {
    @apply flex flex-col gap-16 max-w-[1000px] min-w-[250px] w-full;

    &-logo {
      @apply max-w-[200px] sm:max-w-xs;
    }

    &-experience-text {
      @apply text-2xl;

      > span {
        @apply flex flex-row gap-1;
      }
    }
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables';
@import './mixins';
@import './fonts';

@import 'react-toastify/dist/ReactToastify.css';

html, body, #root {
  @apply h-full overflow-hidden;
}

body {
  background-color: var(--background-color);
  margin: 0;
  font-family: "DM Sans", "DM Sans Placeholder", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply transition duration-300 ease-in-out transform text-gray-900 dark:text-gray-100;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.font-large {
  font-size: 1.25rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.hero {
  @apply flex flex-col items-start gap-10 sm:gap-16 max-w-[1000px] min-w-[250px] w-full py-12 sm:py-24;

  &__logo {
    @apply w-[150px] sm:w-[250px];
  }

  &__experience-text {
    @apply text-xl sm:text-2xl;

    > span {
      @apply flex flex-col sm:flex-row gap-1;
    }
  }

  &__email {
    @apply flex flex-row gap-2 items-center justify-start text-lg sm:text-xl font-medium;
  }
}

.navigation {
  @apply sticky top-0 w-full z-50 p-6 sm:p-16;

  &__container {
    @apply flex flex-row flex-1 justify-between items-center;
  }

  &__logo {
    @apply relative max-w-[3rem] overflow-visible;
  }

  &__actions {
    @apply flex space-x-4 overflow-hidden;
  }
}
